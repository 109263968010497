<template>
  <div class="container">

    <!-- Heading -->
    <h2>{{ brands.heading }}</h2>
    
    <!-- For Sesktop -->
    <div v-if="isDesktop === true" class="list-box">
      <Carousel 
        :per-page="6" 
        :mouse-drag="false"
        :speed="500"
        :loop="shouldLoop"
        :autoplayHoverPause="false"
        :autoplay="startPlaying"
        :scrollPerPage="false"
      >
        <Slide
          class="slide-image"
          v-for="(brand, index) in randomBrands"
          :key="'brand'+index"
        >
          <div class="brand-box">
            <img
              :v-aspect-ratio="1"
              :src="brand.image.url"
              :alt="brand.image.alt"
            >
          </div>
        </Slide>
      </Carousel>
    </div>

    <!-- For Mobile -->
    <div v-if="isDesktop === false" class="list-box">
      <Carousel 
        :per-page="3" 
        :mouse-drag="false"
        :speed="500"
        :loop="shouldLoop"
        :autoplayHoverPause="false"
        :autoplay="startPlaying"
        :scrollPerPage="false"
      >
        <Slide
          class="slide-image"
          v-for="(brand, index) in randomBrands"
          :key="'brand'+index"
        >
          <div class="brand-box">
            <img
              :v-aspect-ratio="1"
              :src="brand.image.url"
              :alt="brand.image.alt"
            >
          </div>
        </Slide>
      </Carousel>
    </div>
    <div 
      class="link-box"
      v-if="brands.view_more"
    >
      <Link
        :link="brands.view_more"
      />
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import throttle from 'lodash/throttle'
import Link from '../components/Link.vue'

export default {
  props: {
    brands: Object
  },
  data () {
    return {
      isDesktop: false,
      eventListenerResize: null,
      startPlaying: true,
      shouldLoop: true
    }
  },
  computed: {
    randomBrands () {
      return (this?.brands?.brands || []) // .sort(() => 0.5 - Math.random()).slice(0, 6)
    }
  },

  created () {
    this.throttleOnResize = throttle(this.onScreenChange.bind(this), 300)
    this.onScreenChange({})
  },
  mounted () {
    this.eventListenerResize = this.$nextTick(() => {
      window.addEventListener('resize', this.throttleOnResize, { passive: true })
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.eventListenerResize)
  },

  methods: {
    onScreenChange (e) {
      if (window.innerWidth > 800) {
        this.isDesktop = true
      } else {
        this.isDesktop = false
      }
    }
  },

  components: {
    Carousel, 
    Slide,
    Link
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables";
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";
@import "../styles/theme";

.container {
  @extend %container;
  @extend %mobile-container;

  margin-bottom: 100px;

  @media (max-width: 800px) {
    margin-bottom: 100px;
  }

  h2 {
    margin-left: d-grid(1);
    margin-right: grid-pct(1);
    margin-top: 0px;
    margin-bottom: 45px;
    font-size: 2.25rem;
    font-weight: normal;
    letter-spacing: -.01em;
    text-align: center;

    @include t3;
    
    @media (max-width: 800px) {
      @include m-t3;
      margin-bottom: 24px;
    }
  }

  .list-box {
    margin-left: auto;
    margin-right: auto;

    padding-left: d-grid(0.90);
    padding-right: grid-pct(0.90);

    /deep/ .VueCarousel-wrapper {
      overflow: visible;
    }
    /deep/ .VueCarousel-pagination {
      display: none;
    }

    .slide-image {
      .brand-box {
        margin-left: auto;
        margin-right: auto;

        padding-left: d-grid(1.5);
        padding-right: d-grid(1.5);
        
        @media (max-width: 800px) {
          padding-left: m-grid(0.26);
          padding-right: m-grid(0.26);
        }

        img {
          width: 100%;
        }
      }
    }
    
  }

  
  .link-box {
    text-align: center;
    margin-top: 30px;

    a {
      text-transform: uppercase;
    }
  }


}

@include link-text-hover-underline('.link-box a', 0px);

@include theme('light') {
  img {
    @include theme-transition;
    filter: invert(100%);
  }
}
</style>
