<template>
  <div class="container">
    <h2>{{ projects.pre_lead }}</h2>
    <p>{{ projects.lead }}</p>
    <Link v-if="projects.cta" :link="projects.cta" v-aspect-ratio />

    <div class="media">
      <template v-for="(item, i) in projects.media">
        <MediaItem
          :key="(item.image && item.image.id) || (item.video && item.video.id)"
          :video="item.video"
          :image="item.image"
          :aspect="(i === 3 ? 1 : 309 / 550)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import MediaItem from './MediaItem'
import Link from './Link'

export default {
  props: {
    projects: Object
  },
  components: {
    MediaItem,
    Link
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/container";
@import "../styles/grid";
@import "../styles/buttons";
@import "../styles/text";

.container {
  @extend %container;
  @extend %mobile-container;
  margin-bottom: 110px; //calc(150px + 53px); // + 17px margin+top on team = 220px
  overflow-x: visible;
   @media (max-width: 800px) {
     overflow-x: hidden;
     margin-bottom: 0px;
   }
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;
  margin-left: grid-pct(1);
  width: grid-pct(4);
  @include b3;
  font-weight: 400;
  letter-spacing: 0.1rem;
  opacity: 0.6;
  white-space: pre-line;
  text-transform: uppercase;
  @media (max-width: 800px) {
    @include m-b3;
    @include m-margin(0.5);
    margin-bottom: 10px;
  }
}

p {
  margin-top: 0;
  margin-bottom: 100px;
  margin-left: grid-pct(1);
  width: grid-pct(4);
  @include t3;
  letter-spacing: -.01em;
  white-space: pre-line;
  @media (max-width: 800px) {
    @include m-t3;
    @include m-margin(0.5);
    margin-bottom: m-grid(1,5px);
  }
}

a {
  @extend %btn-circle;
  position: relative;
  margin-left: grid-pct(3);
  width: grid-pct(2);
  z-index: 1;
  @media (max-width: 800px) {
    @include m-margin(1.5);
    margin-bottom: m-grid(1);
  }
  @media (max-width: 500px) {
    font-size: px(12);
    line-height: px(17);
  }
}

.media-item:nth-child(1) {
  margin-top: grid-pct(-4);
  margin-left: grid-pct(6);
  width: grid-pct(5);
  @media (max-width: 800px) {
    margin-top: 0px;
    margin-left: m-grid(1);
    width: m-grid(5);
    margin-bottom: m-grid(1);
  }
}

.media-item:nth-child(2) {
  margin-left: grid-pct(-3);
  width: grid-pct(5);
  @media (max-width: 800px) {
    margin-left: 0px;
    margin-right: m-grid(2);
    width: m-grid(4);
    margin-bottom: m-grid(1);
  }
}

.media-item:nth-child(3) {
  margin-top: grid-pct(-2);
  margin-left: grid-pct(8);
  width: grid-pct(5);
  @media (max-width: 800px) {
    margin-top: 0px;
    @include m-margin(1);
    margin-bottom: m-grid(1);
  }
}

.media-item:nth-child(4) {
  margin-top: grid-pct(-1.5);
  margin-left: grid-pct(3);
  width: grid-pct(4);
  @media (max-width: 800px) {
    margin-top: 0px;
    margin-left: 0px;
    width: m-grid(6);
    margin-bottom: m-grid(2);
  }
}
</style>
