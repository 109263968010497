<template>
<div>

  <!-- Loading. -->
  <transition name="loading">
    <div v-if="!page" class="loading">
      <Loader/>
    </div>
  </transition>

  <!-- Content. -->
  <div class="page-box content home" v-if="page">
    <BgCross/>
    <VideoModal
      :active="videoModalActive"
      :embed_url="modalVideo.embed_url"
      :height="Number(modalVideo.height)"
      :width="Number(modalVideo.width)"
      @close="videoModalActive = false"
    />
    <HomeHero 
      :mediaItemClick="openVideo" 
      :hero="page.acf.hero" 
      :animatedText="page.acf.hero_animation.animated_text"
      v-if="page.acf.hero && page.acf.hero_animation.animated_text" 
    />
    <HomeProjects 
      :projects="page.acf.projects" 
      v-if="page.acf.projects"
    />

    <HomeBrands
      :brands="page.acf.brands"
      v-if="page.acf.brands"
    />

    <!-- Footer -->
    <Contact2021 
      class="Contact2021"
      :contact="page.acf.contact" 
      v-if="page.acf.contact && page.acf.contact.contact_blurb && page.acf.contact.subtitle && page.acf.contact.title"
    />
    <SubscribeForm2021
      class="SubscribeForm2021"
    />
    <CopyrightFooter
      class="CopyrightFooter"
      v-if="copyright && copyright !== ''"
      :copyright="copyright"
    />
  </div>
</div>
</template>

<script>
import FadeIn from '../mixins/FadeIn'
import MatchedRoute from '../mixins/MatchedRoute'
import Wordpress from '../mixins/Wordpress'
import HomeHero from '../components/HomeHero'
import HomeProjects from '../components/HomeProjects'
import Contact2021 from '../components/Contact2021'
import VideoModal from '../components/VideoModal'
import Loader from '../components/Loader'
import BgCross from '../components/BgCross'
import SubscribeForm2021 from '../components/SubscribeForm2021'
import HomeBrands from '../components/HomeBrands'
import CopyrightFooter from '../components/CopyrightFooter'

export default {
  mixins: [
    FadeIn,
    MatchedRoute,
    Wordpress
  ],
  data () {
    return {
      show: true, // a toggle for quick debug of comps
      page: null,
      videoModalActive: false,
      modalVideo: {
        embed_url: 'https://player.vimeo.com/video/112866269',
        height: 360,
        width: 640
      }
    }
  },
  computed: {
    copyright () {
      return this?.page?.acf?.copyright_footer?.copyright || ''
    }
  },
  methods: {
    openVideo (videoId) {
      // console.log('hi@'+videoId)
      if (videoId === 'large') {
        this.modalVideo = this.page.acf.hero.large_vimeo
        // console.log('large triggered')
      }
      if (videoId === 'small') {
        this.modalVideo = this.page.acf.hero.small_vimeo
        // console.log('small triggered')
      }
      if (videoId === 'studio') {
        this.modalVideo = this.page.acf.studio.vimeo
        // console.log('studio triggered')
      }
      this.videoModalActive = true
    },

    async fetchPage (destinationIsOrigin, targetIsContactSection) {
      let page = null
      if (this.$route.query.preview) {
        page = await this.$wp.getPagePreviewByPath(this.$matchedRoute.pathResolved)
      } else {
        page = await this.$wp.getPageByPath(this.$matchedRoute.pathResolved)
      }
      if (!this.hasPreviousRoute && !this.$route.query.preview) {
        // await this.$wp.delay(3000)
      }
      this.page = page
      // console.log(this.page)

      // Fade Page Content In After Tick.
      if (!destinationIsOrigin && !targetIsContactSection) this.$nextTick(() => this.fadePageContentIn())
    }
  },
  watch: {
    videoModalActive: function (val) {
      if (val === true) {
        this.$router.app.$emit('videoModalOpen')
      } else {
        this.$router.app.$emit('videoModalClose')
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    const destinationIsOrigin = from.name === to.name
    const targetIsContactSection = to.hash === 'contact'
    next(vm => {
      vm.fetchPage(destinationIsOrigin, targetIsContactSection)
      // vm.lastPage = form
    })
  },
  components: {
    HomeHero,
    HomeProjects,
    Contact2021,
    VideoModal,
    Loader,
    BgCross,
    SubscribeForm2021,
    HomeBrands,
    CopyrightFooter
  }
}
</script>

<style lang="scss" scoped>
  @import "./../styles/theme";
  @import "./../styles/container";
  @import "../styles/text";
  @import "../styles/variables";

  
  .loading {
    @include loading;
  }
  @include loading-page-fade;

  .home {
    overflow: hidden;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }

  .page-box {
    margin-bottom: 110px;
  }

  .Contact2021 {
    margin-top: 130px;
    @media (max-width: 800px) {
      margin-top: 110px;
    }
  }

  .SubscribeForm2021 {
    margin-top: 60px;
  }
  
  .CopyrightFooter {
    margin-top: calc(60px - 10px);
  }
</style>
