
<template>
  <div class="container">

    <!-- Mobile Media Item. -->
    <MediaItem
      id="video-1"
      :showButton="(hero.video_large && hero.large_vimeo.embed_url.length > 0 && hero.large_vimeo.height > 0 && hero.large_vimeo.width > 0)"
      buttonSide="top-left"
      v-on:click="() => { mediaItemClick('large') }"
      class="m-media-lg m-show"
      :video="hero.video_large"
      :image="hero.image_large"
      :aspect="1"
      :legacyStyle="true"
      :hideBackground="true"
      />

    <h1>
      <!-- 
        Would you kindly ignore the v-if with the transition-group.
        Ordnarily you don't mix for and if but you do when you use a transition-group
      -->
      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
      <!-- Our Desktop aniamtion -->
      <div class="desktop-logo m-hide">

        <!-- Statinary pluss for desktop -->
        <div 
          :class="['logo-animator', 'statinary', desktopShowStatinaryPluss]"
        >
          <div class="logo-center-pluss">
            <div class="text">
              <div v-resize-text="{delay: 100}" class="word word-left"><span>{{' '}}</span></div>
              <div v-resize-text="{ratio:0.15, delay: 100}" class="text-icon"><span>+</span></div>
              <div v-resize-text="{delay: 100}" class="word word-right"><span>{{' '}}</span></div>
            </div>
          </div>
        </div>


        <transition-group name="logo">
          <div 
            class="logo-animator"
            v-for="(item, index) in desktopAnimatedText"
            v-bind:key="'desktop-logo-item'+index" 
            v-if="index == show"
          >
            <!-- A single line of text -->
            <div 
              :class="{
                'logo-single-line': true,
                'thin': item.text_type == 'single line thin'
              }"
              v-if="item.text_type == 'single line' || item.text_type == 'single line thin'"
            >
              <div 
                v-resize-text="{delay: 100, ratio:0.8, maxFontSize: '45px'}"
                v-html="item.single_line"
              />
            </div>

            <!-- The pluss version of the logo -->
            <div 
              :class="{
                'logo-center-pluss': true,
                'thin': item.text_type == 'centre plus thin'
              }"
              v-if="item.text_type == 'centre plus' || item.text_type == 'centre plus thin'"
            >
              <!-- Text Container for center pluss option -->
              <div class="text">
                <div v-resize-text="{delay: 100}" class="word word-left"><span>{{item.left_text}}</span></div>
                <div v-resize-text="{ratio:0.15, delay: 100}" class="text-icon pluss-hidden"><span>+</span></div>
                <div v-resize-text="{delay: 100}" class="word word-right"><span>{{item.right_text}}</span></div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <!-- eslint-enable -->
      

      <!-- Our Mobile Animation -->
      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
      <div class="mobile-logo">
          <!-- Statinary pluss for mobile -->
          <div 
            :class="['logo-animator', 'm-show', 'statinary', mobileShowStatinaryPluss]"
          >
            <div 
              class="mobile-logo-center-pluss"
              v-resize-text="{ratio:1, delay: 100}"
            >
              <span>{{' '}}</span>
              <span class="text-icon">+</span>
              <span>{{' '}}</span>
            </div>
          </div>

        <transition-group name="mobile-logo">
          <div 
            class="logo-animator  m-show"
            v-for="(item, index) in mobileAnimatedText"
            v-bind:key="'mobile-logo-item'+index" 
            v-if="index == showMobile"
          > 
            <!-- The single line -->
            <div 
              :class="{
                'mobile-logo-single-line': true,
                'thin': item.text_type == 'single line thin'
              }"
              v-if="item.text_type == 'single line' || item.text_type == 'single line thin'"
              v-resize-text="{delay: 100, ratio:1}"
            > 
              <span
                v-if="item.single_line_stacked_on_mobile == true"
                v-for="(subItem, subIdex) in item.single_line.split(' ')"
                v-bind:key="'subitem'+subIdex+index"
              >{{subItem}}</span>
              <span 
                v-if="item.single_line_stacked_on_mobile != true"
                v-html="item.single_line"
              />
            
            </div>

            <!-- The pluss verison of the logo -->
            <div 
              :class="{
                'mobile-logo-center-pluss': true,
                'thin': item.text_type === 'centre plus thin'
              }" 
              v-resize-text="{ratio:1, delay: 100}"
              v-if="item.text_type === 'centre plus' || item.text_type === 'centre plus thin'"
            >
              <span>{{item.left_text}}</span>
              <span class="text-icon pluss-hidden">+</span>
              <span>{{item.right_text}}</span>
            </div>
            
          </div>
        </transition-group>
      </div>
    </h1>

    <div class="m-go-down">
      <!-- This span was an a tag when studio was on the page <a href="#studio"> -->
      <span >
        <svg class="m-show" width="18" height="36" viewBox="0 0 18 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path class="themed-stroke-colour" d="M9 0.666748L9 34.6667" stroke="white" stroke-width="2" stroke-linejoin="round"/>
          <path class="themed-stroke-colour" d="M17 26.6667L9 34.6667L1 26.6667" stroke="white" stroke-width="2" stroke-linejoin="round"/>
          <title>skip to next</title>
        </svg>
      </span>
    </div>

    <div class="text-container">
      <div class="text">
        <p v-text="hero.lead" />
        <Link v-if="hero.lead_link" :link="hero.lead_link" />
      </div>
    </div>

    <MediaItem
      id="video-2"
      :showButton="(hero.video_small && hero.small_vimeo.embed_url.length > 0 && hero.small_vimeo.height > 0 && hero.small_vimeo.width > 0)"
      :buttonSide="'left'"
      v-on:click="mediaItemClick('small')"
      class="media-sm m-hide"
      :video="hero.video_small"
      :image="hero.image_small"
      :hideBackground="true"
    />
    <MediaItem
      id="video-3"
      :showButton="(hero.video_large && hero.large_vimeo.embed_url.length > 0 && hero.large_vimeo.height > 0 && hero.large_vimeo.width > 0)"
      :buttonSide="'right'"
      v-on:click="mediaItemClick('large')"
      class="media-lg m-hide"
      :video="hero.video_large"
      :image="hero.image_large"
      :aspect="1"
      :hideBackground="true"
    />

  </div>
</template>
<script>
import MediaItem from './MediaItem'
import Link from './Link'

export default {
  props: {
    hero: Object,
    animatedText: Array,
    mediaItemClick: Function
  },
   data () {
    return {
      show: -1,
      showMobile: -1,
      interval: null,
      intervalMobile: null,
      anaimtionLength: 2200
    }
  },
  computed: {
    desktopAnimatedText () {
      return (this?.animatedText || []).filter((item) => item?.hide_no_desktop === false)
    },
    mobileAnimatedText () {
      return (this?.animatedText || []).filter((item) => item?.hide_on_mobile === false)
    },
    desktopShowStatinaryPluss () {
      return (
        this.desktopAnimatedText[this.show]?.text_type === 'single line' || 
        this.desktopAnimatedText[this.show]?.text_type === 'single line thin'
        ) ? 'statinary-hide' : 'statinary-show'
    }, 
    mobileShowStatinaryPluss () {
      return (
        this.mobileAnimatedText[this.showMobile]?.text_type === 'single line' || 
        this.mobileAnimatedText[this.showMobile]?.text_type === 'single line thin'
      ) ? 'statinary-hide' : 'statinary-show'
    }
  },
  mounted () {
    this.setAniamtionToggle()
    this.setAniamtionToggleMobile()
  },
  beforeUnmount () {
    clearInterval(this.interval)
    clearInterval(this.intervalMobile)
  },
  methods: {
    setAniamtionToggle () {
      this.interval = setInterval(() => {
        let newShow = this.show += 1
        if (newShow >= this.desktopAnimatedText.length) {
          this.show = 0
        } else {
          this.show = newShow
        }
      }, this.anaimtionLength)
    },
    setAniamtionToggleMobile () {
      this.intervalMobile = setInterval(() => {
        let newShow = this.showMobile += 1
        if (newShow >= this.mobileAnimatedText.length) {
          this.showMobile = 0
        } else {
          this.showMobile = newShow
        }
      }, this.anaimtionLength)
    }
  },
  components: {
    MediaItem,
    Link
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/container";
@import "../styles/grid";
@import "../styles/text";
@import "../styles/theme";
@import "../styles/buttons";
@import "px-par/index.scss";


.statinary {
  /* 'statinary-show' : 'statinary-show' */
  opacity: 1;
  transition: 0.3s;

  &.statinary-hide {
    opacity: 0;
  }
}

.pluss-hidden {
  opacity: 0;
}

$hero-fade-time: 0.4s;

.logo-enter-active {
  animation: logo-in $hero-fade-time;
}
.logo-leave-active {
  animation: logo-out $hero-fade-time;
}

@keyframes logo-in {
  0% {
    transform: translateY(58px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes logo-out {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-58px);
  }
}

.mobile-logo-enter-active {
  animation: mobile-logo-in $hero-fade-time;
}
.mobile-logo-leave-active {
  animation: mobile-logo-out $hero-fade-time;
}

@keyframes mobile-logo-in {
  0% {
    transform: translateX(-240px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes mobile-logo-out {
  0% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(+240px);
  }
}



@include themed-stroke-colour;

.container {
  @extend %container;
  @extend %mobile-container;
  position: relative;
  margin-bottom: 94px + 40px;
  height: 100vh;
  max-height: 1024px;
  z-index: 1;

  @media (max-width: 800px) {
    max-height: none;
    height: auto;
    margin-bottom: 84px;
  }
}

.m-go-down {
  height: 0px;
  overflow: visible;

  span {
    position: absolute;
    display: block;
    margin-left: m-grid(1, 9px);
    margin-top:  m-grid(1, 14px);

  }

}

.text-container {
  /* Build Container that is same size as video  */
  @extend %container;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50vw;
  max-height: 720px;
  transform: translate(-50%,-50%);
  pointer-events: none; // turn off pointer events as container covers other clickable items

  @media (max-width: 800px) {
    /* Reset styles so that text is back in page flow */
    position: relative;
    width: auto;
    height: auto;
    max-height: none;

    left: 0px;
    right: 0px;
    margin: 0px;
    padding: 0px;

    transform: none;
    /* Set Mobile Padding & size */
    @include box;
    /* margin-left: m-grid(0.4);
    margin-right: m-grid(0.4); */
  }

  .text {
    /* Fill Space and Move Children To Bottom */
    position: absolute;
    left: 0px;
    right: 50%;
    top:0px;
    bottom: 0px;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: 800px) {
      right: 0px;
      margin-top: 14px;
      position: relative;
    }

    p {
      width: #{px_par(660, 440)};
      margin: 0px;
      margin-left: auto;
      margin-right: auto;
      @include b1;
      white-space: pre-line;

      @media (max-width: 980px) {
        @include m-b2;
      }

      @media (max-width: 800px) {
        width: m-grid(2.6); // 3-0.4padding
        margin-left: m-grid(2);
        margin-right: auto;
        @include m-b2;
      }

      @media (max-width: 600px) {
        width: m-grid(3.6); // 4-0.4padding
      }
    }

    /* The CTA Link */
    a {
      @extend %btn-outline-red;
      align-self: flex-start;
      margin-top: 32px;
      margin-left: #{px_par(660,110)};
      pointer-events: all; // turn on pointer evens so the cta can be clicked

      @media (max-width: 800px) {
        margin-left: m-grid(2);
        margin-bottom: #{px_par(660, 220)};
        @include m-b3;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: right;
      }

      @media (max-width: 400px) {
        width: m-grid(5.2); // 6 - 0.4padding - 0.4padding
        margin-left: m-grid(0.4);
        margin-right: m-grid(0.4);

      }

    }
  }
}

.container::before {
  display: block;
  height: 50%;
  content: '';
  pointer-events: none;
  @media (max-width: 800px) {
    height: 96px;
   }
}

.m-media-lg {
  @include m-margin(0.5);
}

h1 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 0;
  @media (max-width: 800px) {
    height: 36px;
  }
}

.desktop-logo {
  display: block;
  position: relative;
  width: 100%;
  height: 58px;
}

/* The Desktop version of the logo */
.logo-single-line {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: (-58px / 2);
  margin-left: auto;
  margin-right: auto;
  height: 58px;
  width: 100%;
  pointer-events: none;
 
  font-weight: normal;
  font-size: 56px;
  letter-spacing: 0.5em;
  text-align: center;

  /deep/.red {
    color: $red;
  }

  &.thin {
    letter-spacing: -.01em;
  }
}

.logo-center-pluss {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: (-58px / 2);
  height: 58px;
  width: 100%;
  pointer-events: none;
 
  font-weight: normal;
  font-size: 56px;
  letter-spacing: 0.5em;

  &.thin {
    letter-spacing: -.01em;

    .word.word-left {
      span { margin-right: unset !important; }
    }
  }

  .text {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .text-icon {
    width: 8%;
    height: 58px;
    flex-shrink: 0;
    flex-grow: 1;
    flex-basis: 8%;

    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
    letter-spacing: 0px; /* This clears extra spaceing */

    span {
      color: $red;
    }
  }
  

  .word {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(50% - 4%);
    box-sizing: border-box;

    &.word-left {
      text-align: right;
      span { margin-right: -0.5em; }
    }

    &.word-right {
      text-align: left;
      span { margin-right: -0.5em; }
    }
  }
}

/**
* The mobile version of the logo 
*/
.mobile-logo {
  width: 100%;
  height: 151px;
  transform: translateY(calc(-89% - 2px));

  .mobile-logo-single-line {
    position: absolute;
    width: 240px;
    height: 151px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include m-margin(1);
    pointer-events: none;
    text-align: center;
    font-weight: normal;
    letter-spacing: 0.5em;
    
    /deep/.red { color: $red; }

    &.thin {
      letter-spacing: -.01em;
    }
  }

  .mobile-logo-center-pluss {
    position: absolute;
    width: 240px;
    height: 151px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include m-margin(1);
    
    pointer-events: none;
    text-align: center;
    font-weight: normal;

    letter-spacing: 0.5em;

    &.thin {
      letter-spacing: -.01em;
    }

    .text-icon { color: $red;  }
    span { margin-right: -0.5em; }
  }
}

.scroll-down {
  margin-top: 80px;
  display: flex;
  width: 100%;
  justify-content: center;
  transform: translateX(-360px);
  pointer-events: none; // turn off container click
  a { pointer-events: all; } // turn back on click for down

  @media (max-width: 1000px) { // on body shrinking
    transform: translateX(-36%);
  }

  @media (max-width: 800px) { // on mobile where log is now fixed sized and smaller
    transform: translateX(-55px);
    margin-top: 32px;
  }
}

.media-sm { // video-2
  position: absolute;
  max-width: 440px;
  top: 0;
  right: calc(50% + 220px);
  bottom: calc(50% + 220px);
  // height: calc(50% - 220px);
  margin-top: -38px;
  z-index: -1;
}

.media-lg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: (-50vw / 2);
  width: 50vw;
  z-index: -1;

  @media (min-width: 1440px) {
    margin-top: (-720px / 2);
    width: 720px;
  }
}

</style>
