var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('MediaItem',{staticClass:"m-media-lg m-show",attrs:{"id":"video-1","showButton":(_vm.hero.video_large && _vm.hero.large_vimeo.embed_url.length > 0 && _vm.hero.large_vimeo.height > 0 && _vm.hero.large_vimeo.width > 0),"buttonSide":"top-left","video":_vm.hero.video_large,"image":_vm.hero.image_large,"aspect":1,"legacyStyle":true,"hideBackground":true},on:{"click":function () { _vm.mediaItemClick('large') }}}),_c('h1',[_c('div',{staticClass:"desktop-logo m-hide"},[_c('div',{class:['logo-animator', 'statinary', _vm.desktopShowStatinaryPluss]},[_c('div',{staticClass:"logo-center-pluss"},[_c('div',{staticClass:"text"},[_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({delay: 100}),expression:"{delay: 100}"}],staticClass:"word word-left"},[_c('span',[_vm._v(_vm._s(' '))])]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({ratio:0.15, delay: 100}),expression:"{ratio:0.15, delay: 100}"}],staticClass:"text-icon"},[_c('span',[_vm._v("+")])]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({delay: 100}),expression:"{delay: 100}"}],staticClass:"word word-right"},[_c('span',[_vm._v(_vm._s(' '))])])])])]),_c('transition-group',{attrs:{"name":"logo"}},_vm._l((_vm.desktopAnimatedText),function(item,index){return (index == _vm.show)?_c('div',{key:'desktop-logo-item'+index,staticClass:"logo-animator"},[(item.text_type == 'single line' || item.text_type == 'single line thin')?_c('div',{class:{
              'logo-single-line': true,
              'thin': item.text_type == 'single line thin'
            }},[_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({delay: 100, ratio:0.8, maxFontSize: '45px'}),expression:"{delay: 100, ratio:0.8, maxFontSize: '45px'}"}],domProps:{"innerHTML":_vm._s(item.single_line)}})]):_vm._e(),(item.text_type == 'centre plus' || item.text_type == 'centre plus thin')?_c('div',{class:{
              'logo-center-pluss': true,
              'thin': item.text_type == 'centre plus thin'
            }},[_c('div',{staticClass:"text"},[_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({delay: 100}),expression:"{delay: 100}"}],staticClass:"word word-left"},[_c('span',[_vm._v(_vm._s(item.left_text))])]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({ratio:0.15, delay: 100}),expression:"{ratio:0.15, delay: 100}"}],staticClass:"text-icon pluss-hidden"},[_c('span',[_vm._v("+")])]),_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({delay: 100}),expression:"{delay: 100}"}],staticClass:"word word-right"},[_c('span',[_vm._v(_vm._s(item.right_text))])])])]):_vm._e()]):_vm._e()}),0)],1),_c('div',{staticClass:"mobile-logo"},[_c('div',{class:['logo-animator', 'm-show', 'statinary', _vm.mobileShowStatinaryPluss]},[_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({ratio:1, delay: 100}),expression:"{ratio:1, delay: 100}"}],staticClass:"mobile-logo-center-pluss"},[_c('span',[_vm._v(_vm._s(' '))]),_c('span',{staticClass:"text-icon"},[_vm._v("+")]),_c('span',[_vm._v(_vm._s(' '))])])]),_c('transition-group',{attrs:{"name":"mobile-logo"}},_vm._l((_vm.mobileAnimatedText),function(item,index){return (index == _vm.showMobile)?_c('div',{key:'mobile-logo-item'+index,staticClass:"logo-animator  m-show"},[(item.text_type == 'single line' || item.text_type == 'single line thin')?_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({delay: 100, ratio:1}),expression:"{delay: 100, ratio:1}"}],class:{
              'mobile-logo-single-line': true,
              'thin': item.text_type == 'single line thin'
            }},[_vm._l((item.single_line.split(' ')),function(subItem,subIdex){return (item.single_line_stacked_on_mobile == true)?_c('span',{key:'subitem'+subIdex+index},[_vm._v(_vm._s(subItem))]):_vm._e()}),(item.single_line_stacked_on_mobile != true)?_c('span',{domProps:{"innerHTML":_vm._s(item.single_line)}}):_vm._e()],2):_vm._e(),(item.text_type === 'centre plus' || item.text_type === 'centre plus thin')?_c('div',{directives:[{name:"resize-text",rawName:"v-resize-text",value:({ratio:1, delay: 100}),expression:"{ratio:1, delay: 100}"}],class:{
              'mobile-logo-center-pluss': true,
              'thin': item.text_type === 'centre plus thin'
            }},[_c('span',[_vm._v(_vm._s(item.left_text))]),_c('span',{staticClass:"text-icon pluss-hidden"},[_vm._v("+")]),_c('span',[_vm._v(_vm._s(item.right_text))])]):_vm._e()]):_vm._e()}),0)],1)]),_c('div',{staticClass:"m-go-down"},[_c('span',[_c('svg',{staticClass:"m-show",attrs:{"width":"18","height":"36","viewBox":"0 0 18 36","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{staticClass:"themed-stroke-colour",attrs:{"d":"M9 0.666748L9 34.6667","stroke":"white","stroke-width":"2","stroke-linejoin":"round"}}),_c('path',{staticClass:"themed-stroke-colour",attrs:{"d":"M17 26.6667L9 34.6667L1 26.6667","stroke":"white","stroke-width":"2","stroke-linejoin":"round"}}),_c('title',[_vm._v("skip to next")])])])]),_c('div',{staticClass:"text-container"},[_c('div',{staticClass:"text"},[_c('p',{domProps:{"textContent":_vm._s(_vm.hero.lead)}}),(_vm.hero.lead_link)?_c('Link',{attrs:{"link":_vm.hero.lead_link}}):_vm._e()],1)]),_c('MediaItem',{staticClass:"media-sm m-hide",attrs:{"id":"video-2","showButton":(_vm.hero.video_small && _vm.hero.small_vimeo.embed_url.length > 0 && _vm.hero.small_vimeo.height > 0 && _vm.hero.small_vimeo.width > 0),"buttonSide":'left',"video":_vm.hero.video_small,"image":_vm.hero.image_small,"hideBackground":true},on:{"click":function($event){return _vm.mediaItemClick('small')}}}),_c('MediaItem',{staticClass:"media-lg m-hide",attrs:{"id":"video-3","showButton":(_vm.hero.video_large && _vm.hero.large_vimeo.embed_url.length > 0 && _vm.hero.large_vimeo.height > 0 && _vm.hero.large_vimeo.width > 0),"buttonSide":'right',"video":_vm.hero.video_large,"image":_vm.hero.image_large,"aspect":1,"hideBackground":true},on:{"click":function($event){return _vm.mediaItemClick('large')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }